import G6 from '@antv/g6'

export default {
    data() {
        return {
            pageSize: 20,
            antv: null,
            loading: false,

            showSelect: true,
        }
    },
    methods: {
        goIndication(row){
            this.$emit('goIndication',row)
        },
        setCountField(row){
            this.$emit('setCountField',row)
        },
        renderG6(data) {
            const container = document.getElementById(this.key)
            const graph = new G6.TreeGraph({
                container: container,
                width: container.scrollWidth,
                height: container.scrollHeight,
                plugins: [
                    new G6.Tooltip({
                        offsetX: 10,
                        offsetY: 20,
                        getContent(e) {
                            const outDiv = document.createElement('div');
                            outDiv.style.minWidth = '100px';
                            const model = e.item.getModel()
                            let html =  model.label
                            if (!model.isFull) {
                                html += '<br/>销售额：'
                                if (model.data && model.data.saleAmount && model.data.saleAmount != 0) {
                                    html += `${model.data.saleAmount}万元`
                                } else {
                                    html += '--'
                                }
                            }
                            outDiv.innerHTML = html
                            return outDiv
                        },
                        itemTypes: ['node']
                    })
                ],
                modes: {
                    default: [
                        'collapse-expand',
                        'drag-canvas',
                        'zoom-canvas',
                    ],
                },
                defaultNode: {
                    size: 26,
                    anchorPoints: [
                        [0, 0.5],
                        [1, 0.5],
                    ],
                    style: {
                        lineWidth: 0,
                        fill: '#576EEB',
                        cursor: 'pointer',
                    },
                },
                defaultEdge: {
                    type: 'cubic-horizontal',
                },
                layout: {
                    type: 'compactBox',
                    direction: 'LR',

                    getId: function getId(d) {
                        return d.id;
                    },
                    getHeight: function getHeight() {
                        return 16;
                    },
                    getWidth: function getWidth() {
                        return 16;
                    },
                    getVGap: function getVGap(d) {
                        return d.end ? 20 : 8
                    },
                    getHGap: function getHGap(d) {
                        return d.lineWidth || 100
                    },
                },
            })
            graph.node(node => {
                return {
                    label: node.label,
                    type: node.nodeType,
                    labelCfg: {
                        offset: 5,
                        position: node.depth === 0 ? 'left' : 'right',
                    },
                }
            })
            graph.data(data)
            graph.render()
            graph.fitCenter()

            window.onresize = () => {
                if (!graph || graph.get('destroyed')) return
                if (!container || !container.scrollWidth || !container.scrollHeight) return
                graph.changeSize(container.scrollWidth, container.scrollHeight)
            }

            return graph
        },

        // other: 其他一些配置
        getNodeList(list, config, other) {
            const arr = list.map(ele => {
                return this.getNode(ele, config)
            })

            // 判断是否需要“查看全部”
            if (arr.length > 0) {
                arr.push(this.getSeeAllNode(config, other))
            } else {
                arr[arr.length - 1].end = true
            }

            return arr
        },
        getNode(ele, config) {
            return {
                id: this.$help.getUuid(),
                label: `${ele[config.showField]}(${ele[config.countField] || 0})`,
                clearLabel: ele[config.showField],
                loaded: false,
                currentType: config.type,
                nextType: config.nextType,
                data: ele,
                style: {
                    fill: config.color || undefined,
                },
                lineWidth: 180,
                children: [],
            }
        },
        getSeeAllNode(config, other) {
            return {
                id: this.$help.getUuid(),
                label: '查看全部',
                isFull: true,
                parentTitle: other.parentTitle,
                parentModel: other.parentModel.data,
                loaded: false,
                data: {},
                end: true,
                currentType: config.type,
                nextType: config.nextType,
                nodeType: 'rect',
                style: {
                    fill: ' #DC2525',
                },
                config: config,
                lineWidth: 180,
                children: [],
            }
        },
    }
}